export const Video = () => {

    return (
        <div className='max-w-7xl mx-auto px-4 py-16'>
            <div className='absolute dots w-40 h-40 -translate-x-12 -translate-y-6' />
            <div className='relative'>
                <h3 className='text-4xl'>Une vidéo explicative.</h3>
                <p className='text-xl md:text-2xl text-gray-600'>Nous offrons une haute qualité à un prix abordable.</p>
                <div className='flex justify-center mt-8'>
                    {/* <iframe className='max-w-full' loading="lazy" width="640" height="360" src="https://www.youtube.com/embed/E3tYu3X0QZs" title="BFI Bâtiment, Entreprise De Travaux &amp; Rénovation en Seine Et Marne (77)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                    <iframe title="ytbvideo"
                        srcDoc="
        <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
            }
        </style>
        <a
            href='https://www.youtube.com/embed/E3tYu3X0QZs'
            class='full'
        >
            <img
                src='https://bfibatiment.fr/thumb.webp'
                class='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "
                        style={{ maxWidth: '640px', width: '100%', aspectRatio: '16/9' }}
                        frameBorder="0"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}