import quality1 from 'assets/advantages/1.svg'
import quality2 from 'assets/advantages/2.svg'
import quality3 from 'assets/advantages/3.svg'

export const Advantages = () => {

    return (
        <div id='aboutus' className='py-16 px-4'>
            <div className='absolute dots w-40 h-40 -translate-x-12 -translate-y-6' />
            <div className='flex flex-col lg:flex-row justify-center gap-16 relative z-10'>
                <h2 className='text-3xl md:max-w-xs text-center md:text-start'>Pourquoi faire confiance à BFI Bâtiment ?</h2>

                <div className='flex flex-wrap md:flex-nowrap justify-center gap-16'>
                    <QualityBox svg={quality1} title='Qualité' content='Nous offrons une haute qualité à un prix abordable.' />
                    <QualityBox svg={quality2} title='Prix' content='Un prix abordable, ne payez pas un centime de plus.' />
                    <QualityBox svg={quality3} title='Facile' content="Faites une estimation en ligne et nous vous rappellerons." />
                </div>
            </div>
        </div>
    )
}

interface QualityBoxProps {
    title: string;
    content: string;
    svg: string;
}

const QualityBox = (props: QualityBoxProps) => {
    const { title, content, svg } = props

    return (
        <div className='flex flex-col items-center max-w-[15rem] text-center'>
            <div>
                <img src={svg} alt={title} width={57} height={57} />
            </div>
            <h3 className='text-lg font-bold py-2'>{title}</h3>
            <p>{content}</p>
        </div>
    )
}