import { Main } from './containers/main/Main'
import { Advantages } from './containers/advantages/advantages'
import { Services } from './containers/services/services'
import { Contact } from 'components/contact/contact'
import { Footer } from 'layout/components/footer/footer'
import { Reviews } from './containers/reviews/reviews'
import { Video } from './containers/video/video'

export interface ReviewProps {
    author_name: string;
    profile_photo_url: string;
    rating: number;
    text: string;
}

export const Home = () => {

    return (
        <>
            <Main />
            <div className='max-w-7xl mx-auto'>
                <Advantages />
            </div>
            <Services />
            <Video />
            <Contact gray />
            <Reviews reviews={[{ author_name: 'Sergiu Loghin', rating: 5, text: 'Haute qualite!', profile_photo_url: '' }]} />
            <Footer rating={5} user_ratings_total={1} />
        </>
    )
}