import { useNavigate } from 'react-router-dom'
import { Button } from '@mantine/core'

import { Service } from './components/Service'

import couv from 'assets/background.webp'
import charpente from 'assets/services/charpente.webp'
import zinguerie from 'assets/services/zinguerie.webp'
import isolation from 'assets/services/isolation.webp'
import surelevation from 'assets/services/surelevation.webp'

export const Services = () => {
    const navigate = useNavigate()

    return (
        <div id='services' className='bg-[#f7f7f7]'>
            <div className='max-w-7xl mx-auto py-16 px-4'>
                <div className='absolute dots w-40 h-40 -translate-x-8 -translate-y-6' />
                <h2 className='text-4xl mb-8 relative'>Nos réalisations:</h2>
                <div className='flex flex-wrap justify-center gap-8'>
                    <Service url='/couverture' img={couv} title='Couverture' />
                    <Service url='/charpente' img={charpente} title='Charpente' />
                    <Service url='/zinguerie' img={zinguerie} title='Zinguerie' />
                    <Service url='/surelevation' img={surelevation} title='Surélévation' />
                    <Service url='/isolation' img={isolation} title='Isolation' />
                    <div className='w-[320px] h-[320px] overflow-hidden flex flex-col justify-between p-4'>
                        <p className='text-2xl font-bold'>Economisez jusqu'à 420€ sur votre facture de chauffage grâce à l'isolation des murs !</p>
                        <div className='flex justify-end'>
                            <Button size='md' className='mt-4 bg-[#2f3543] hover:bg-[#2f3543]' onClick={() => {
                                navigate('/isolation')
                                window.scrollTo({ top: 0 })
                            }}>J'en profite !</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}