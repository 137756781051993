import { useLocation, useNavigate } from 'react-router-dom'

import { GrFacebook } from 'react-icons/gr'
import { BsTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { AiFillStar } from 'react-icons/ai'
import { FaMapMarkerAlt } from 'react-icons/fa'

export const Footer = (props: { rating: number, user_ratings_total: number }) => {
    const { rating, user_ratings_total } = props
    const navigate = useNavigate()
    const location = useLocation()

    const handleLogoClick = () => {
        if (['/couverture', '/charpente', '/zinguerie', '/surelevation', '/isolation'].includes(location.pathname)) {
            navigate('/')
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleLinks = async (url: string) => {
        if (['/couverture', '/charpente', '/zinguerie', '/surelevation', '/isolation'].includes(url)) {
            await navigate(url)
            window.scrollTo({ top: 0 })
        } else {
            await navigate('/')
            window.scrollTo({ top: (document.getElementById(url)?.getBoundingClientRect().top || 0) + window.pageYOffset - 100, behavior: 'smooth' })
        }
    }

    return (
        <div className='bg-gray-800'>
            <div className='max-w-7xl mx-auto pt-16 pb-8 px-4 text-white'>
                <div className='flex justify-between flex-col md:flex-row'>
                    <div>
                        <div className='flex flex-col leading-none font-extrabold text-4xl cursor-pointer' onClick={handleLogoClick}>
                            <p>BFI</p>
                            <p>BÂTIMENT</p>
                        </div>
                        <div className='mt-4 md:mt-8'>
                            <div className='flex'>{Array.from(Array(rating).keys()).map(x => <AiFillStar key={x} color='orange' size={24} />)}</div>
                            <a href='a' target='_blank' rel='noreferrer' className='underline'>{user_ratings_total} Avis</a>
                        </div>
                    </div>
                    <div>
                        <p className='font-bold text-lg mb-4 hidden md:block'>LIENS</p>
                        <div className='grid grid-cols-2 py-4 md:py-0 gap-2'>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('home')}>Accueil</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('aboutus')}>À propos</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/couverture')}>Couverture</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/charpente')}>Charpente</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/zinguerie')}>Zinguerie</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/surelevation')}>Surélévation</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/isolation')}>Isolation</p>
                        </div>
                    </div>
                    <div>
                        <p className='font-bold text-lg mb-4 hidden md:block'>CONTACT</p>
                        <a href={`tel:${process.env.REACT_APP_PHONE_CALL}`} className='flex items-center gap-4 mt-4'>
                            <BsTelephoneFill size={24} />
                            <p>{process.env.REACT_APP_PHONE}</p>
                        </a>
                        <a href={`tel:${process.env.REACT_APP_PHONE2_CALL}`} className='flex items-center gap-4 mt-4'>
                            <BsTelephoneFill size={24} />
                            <p>{process.env.REACT_APP_PHONE2}</p>
                        </a>
                        <a href={`mailto:${process.env.REACT_APP_EMAIL}`} className='flex items-center gap-4 mt-4'>
                            <MdEmail size={24} />
                            <p>{process.env.REACT_APP_EMAIL}</p>
                        </a>
                        <p className='flex items-center gap-4 mt-4'>
                            <FaMapMarkerAlt size={24} />
                            <p>2 Rue des Brûlis, 77320 Jouy-sur-Morin</p>
                        </p>
                    </div>
                </div>
                <div className='h-[1px] w-full bg-white my-8' />
                <div className='flex items-center flex-col text-center'>
                    <p>© 2023 BFI BÂTIMENT. Tous droits réservés.</p>
                    <span>Réalisé par <a className='underline' href='mailto:sergiu.loghin2003@gmail.com'>Sergiu Loghin</a></span>
                </div>
            </div>
        </div>
    )
}