import { ReactNode } from 'react'

import { Navbar } from './components/navbar/navbar'
import { Footer } from './components/footer/footer';

interface MainLayoutProps {
    children: ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
    const { children } = props

    return (
        <main>
            <Navbar />
            {children}
            {/* <Footer rating={5} user_ratings_total={1} /> */}
        </main>
    )
}