import { useState } from 'react'

import { Skeleton } from '@mantine/core'

import { Contact } from 'components/contact/contact'
import { Footer } from 'layout/components/footer/footer'
import { Reviews } from 'pages/home/containers/reviews/reviews'

import { importAll } from 'utils/utils'

import charpente from 'assets/services/charpente.webp'

export const Charpente = () => {
    const images = importAll(require.context('assets/realisations/charpente', false, /\.(png|jpe?g|svg|webp)$/));

    const [loading, setLoading] = useState(Object.keys(images).reduce((acc: any, curr: any) => {
        acc[curr] = true;
        return acc;
    }, {}))

    const handleImageLoad = (key: string) => {
        setLoading((prev: any) => ({ ...prev, [key]: false }))
    }

    return (
        <>
            <div className='charpente-bg'>
                <div className='bg-black bg-opacity-70 h-full'>
                    <div className='flex flex-col items-center justify-center h-full text-white'>
                        <h1 className='text-6xl lg:text-7xl text-center mt-16 px-4'>CHARPENTE</h1>
                    </div>
                </div>
            </div>

            <div className='bg-[#f7f7f7]'>
                <div className='max-w-7xl mx-auto py-16 px-4'>
                    <div className='flex flex-col md:flex-row gap-8'>
                        <img className='object-cover w-[384px] h-[256px] relative z-10' src={charpente} alt="charpente" />
                        <div>
                            <div className='absolute dots w-40 h-40 -translate-x-14 -translate-y-12 z-0' />
                            <div className='relative text-lg'>
                                <h2 className='text-4xl mb-2'>Charpente : à quoi sert-elle ?</h2>
                                <p>La charpente remplit plusieurs fonctions essentielles. La fonction d'une charpente est de soutenir la couverture et de contribuer à la mise hors air et hors eau de l'ensemble. Soumise à différentes forces de compression, de flexion, de traction, elle est conçue selon une technique très particulière prenant en compte l'équilibre et la répartition des charges.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto px-4 py-16'>
                <div className='absolute dots w-40 h-40 -translate-x-12 -translate-y-6' />
                <div className='relative'>
                    <h3 className='text-4xl'>Nos réalisations de charpente.</h3>
                    <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center pt-8'>
                        {Object.keys(images).map((key: string, i: number) => (
                            <div key={i}>
                                {loading[key] && (
                                    <Skeleton w={296} h={296} />
                                )}
                                <img className='w-full h-full object-cover aspect-square' src={images[key]} alt={key} loading='lazy' onLoad={() => handleImageLoad(key)} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Contact gray />
            <Reviews reviews={[{ author_name: 'Sergiu Loghin', rating: 5, text: 'Haute qualite!', profile_photo_url: '' }]} />
            <Footer rating={5} user_ratings_total={1} />
        </>
    )
}