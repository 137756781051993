import { Button } from '@mantine/core'

export const Main = () => {

    return (
        <div id='home' className='main-bg'>
            <div className='bg-black bg-opacity-70 h-full'>
                <div className='flex flex-col items-center justify-center h-full text-white px-4'>
                    <h1 className='text-6xl lg:text-7xl text-center'>BFI BÂTIMENT</h1>
                    <h2 className='text-2xl lg:text-2xl max-w-xl text-center'>Nous sommes prêts à prendre en charge vos travaux!</h2>
                    <Button size='md' variant='outline' className='mt-4 border-white text-white' onClick={() => window.scrollTo({ top: (document.getElementById('calculation')?.getBoundingClientRect().top || 0) + window.pageYOffset - 100, behavior: 'smooth' })}>
                        DEVIS GRATUIT
                    </Button>
                </div>
            </div>
        </div>
    )
}