import { useState } from 'react'
import { Burger, Button } from '@mantine/core'
import { FiPhoneCall, FiMail } from 'react-icons/fi'

import { Link } from './components/link'
import { useLocation, useNavigate } from 'react-router-dom'

import logo from 'assets/logo/logo.webp';

export const Navbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [opened, setOpened] = useState(false)

    const handleLogoClick = () => {
        if (['/isolation', '/couverture', '/charpente', '/zinguerie', '/surelevation'].includes(location.pathname)) {
            navigate('/')
        }
        window.scrollTo({ top: 0, behavior: ['/isolation', '/couverture', '/charpente', '/zinguerie', '/surelevation'].includes(location.pathname) ? 'auto' : 'smooth' })
    }

    return (
        <nav className='top-0 fixed w-full bg-white z-50 select-none'>
            <div className='max-w-[100rem] mx-auto flex items-center justify-between py-4 px-4'>
                <div className='flex items-center gap-16'>
                    <div className='cursor-pointer' onClick={handleLogoClick}>
                        <img width={150} src={logo} alt='logo' />
                    </div>
                    <ul className='hidden lg:flex gap-8 whitespace-nowrap'>
                        <Link label='À PROPOS' url='aboutus' scrolling />
                        <Link label='NOS SERVICES' url='services' scrolling />
                        <Link label='CONTACT' url='contact' scrolling />
                    </ul>
                </div>
                <div className='flex items-center gap-4'>
                    <ul className='hidden md:flex items-center gap-4'>
                        <li className='flex gap-4 cursor-pointer items-center'>
                            <a href={`tel:${process.env.REACT_APP_PHONE_CALL}`}><FiPhoneCall size={28} /></a>
                            <a href={`tel:${process.env.REACT_APP_PHONE_CALL}`} className='hidden xl:block'>{process.env.REACT_APP_PHONE}</a>
                        </li>
                        <li>
                            <Button size='md' className='bg-[#023878] hover:bg-[#023878]' onClick={() => window.scrollTo({ top: (document.getElementById('contact')?.getBoundingClientRect().top || 0) + window.pageYOffset - 100, behavior: 'smooth' })}>
                                DEVIS GRATUIT
                            </Button>
                        </li>
                    </ul>
                    <Burger className='lg:hidden' opened={opened} onClick={() => setOpened(prev => !prev)} size='lg' color='#2f3543' />
                </div>
                {opened && (
                    <div className={`absolute flex right-0 w-full h-screen overflow-hidden top-[85px] bg-white list-none`}>
                        <div className='py-16 text-lg flex flex-col items-center w-full slide-up gap-8'>
                            <Link label='À PROPOS' url='home' scrolling className='slide-up' onClick={() => setOpened(false)} />
                            <Link label='NOS SERVICES' url='services' scrolling className='slide-up' onClick={() => setOpened(false)} />
                            <Link label='CONTACT' className='slide-up' onClick={() => setOpened(false)} />

                            <div className='slide-up'>
                                <div className='flex flex-col gap-4'>
                                    <a href={`tel:${process.env.REACT_APP_PHONE_CALL}`} className='flex flex-col items-center gap-2'>
                                        <FiPhoneCall size={38} stroke='#2F3543' />
                                        <p>{process.env.REACT_APP_PHONE_CALL}</p>
                                    </a>
                                    <a href={`mailto:${process.env.REACT_APP_EMAIL}`} className='flex flex-col items-center gap-2 whitespace-break-spaces'>
                                        <FiMail size={38} stroke='#2F3543' />
                                        <p>{process.env.REACT_APP_EMAIL}</p>
                                    </a>
                                </div>
                            </div>
                            <Button size='md' className='bg-[#023878] hover:bg-[#023878] md:hidden' onClick={() => {
                                setOpened(false)
                                window.scrollTo({ top: (document.getElementById('contact')?.getBoundingClientRect().top || 0) + window.pageYOffset - 100, behavior: 'smooth' })
                            }}>
                                DEVIS GRATUIT
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    )
}