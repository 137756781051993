import { useState } from 'react'

import { Skeleton } from '@mantine/core'

import { Contact } from 'components/contact/contact'
import { Footer } from 'layout/components/footer/footer'
import { Reviews } from 'pages/home/containers/reviews/reviews'

import { importAll } from 'utils/utils'

import isolation from 'assets/services/isolation2.webp'
import dpe from 'assets/dpe.webp'

export const Isolation = () => {
    const images = importAll(require.context('assets/realisations/isolation', false, /\.(png|jpe?g|svg|webp)$/));

    const [loading, setLoading] = useState(Object.keys(images).reduce((acc: any, curr: any) => {
        acc[curr] = true;
        return acc;
    }, {}))

    const handleImageLoad = (key: string) => {
        setLoading((prev: any) => ({ ...prev, [key]: false }))
    }

    return (
        <>
            <div className='isolation-bg'>
                <div className='bg-black bg-opacity-70 h-full'>
                    <div className='flex flex-col items-center justify-center h-full text-white'>
                        <h1 className='text-6xl lg:text-7xl text-center mt-16 px-4'>ISOLATION</h1>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto py-16 px-4'>
                <div className='flex flex-col md:flex-row gap-8'>
                    <img className='object-cover max-w-[384px] h-[256px] relative z-10' src={isolation} alt="isolation" />
                    <div>
                        <div className='absolute dots w-40 h-40 -translate-x-14 -translate-y-12 z-0' />
                        <div className='relative text-lg'>
                            <h2 className='text-4xl mb-2'>Pourquoi isoler l'extérieur ?</h2>
                            <span className='text-lg'>Avec une isolation par l'extérieur, vos murs sont plus hermétiques, et donc protégés des agressions du froid ou du soleil. De plus, les ponts thermiques responsables des déperditions de chaleur et de la désagréable sensation de <span className='font-semibold'>« paroi froide »</span> de vos murs, sont quasiment tous supprimés.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-[#f7f7f7]'>
                <div className='max-w-7xl mx-auto py-16 px-4'>
                    <div className='flex flex-col-reverse md:flex-row items-center text-center md:text-start'>
                        <img className='max-w-[250px] mt-4 md:mt-0' src={dpe} alt='dpe' />
                        <div>
                            <h1 className='text-4xl z-20'>Quels sont les avantages de l'isolation ?</h1>
                            <h3 className='mt-2 text-lg'>L'isolation fait partie des travaux incontournables pour réduire vos dépenses énergétiques, améliorer votre confort ou faire augmenter la valeur de votre maison. L'isolation d'une maison, en particulier du toit et des murs extérieurs, <span className='font-semibold'>peut empêcher plus de 50 % des pertes de chaleur</span>.</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto px-4 py-16'>
                <div className='absolute dots w-40 h-40 -translate-x-12 -translate-y-6' />
                <div className='relative'>
                    <h3 className='text-4xl'>Nos réalisations d'isolation.</h3>
                    <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center pt-8'>
                        {Object.keys(images).length === 0 && <p>Pas de réalisations.</p>}
                        {Object.keys(images).length > 0 && Object.keys(images).map((key: string, i: number) => (
                            <div key={i}>
                                {loading[key] && (
                                    <Skeleton w={296} h={296} />
                                )}
                                <img className='w-full h-full object-cover aspect-square' src={images[key]} alt={key} loading='lazy' onLoad={() => handleImageLoad(key)} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Contact gray />
            <Reviews reviews={[{ author_name: 'Sergiu Loghin', rating: 5, text: 'Haute qualite!', profile_photo_url: '' }]} />
            <Footer rating={5} user_ratings_total={1} />
        </>
    )
}