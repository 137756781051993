import { useState } from 'react'

import { Skeleton } from '@mantine/core'

import { Contact } from 'components/contact/contact'
import { Footer } from 'layout/components/footer/footer'
import { Reviews } from 'pages/home/containers/reviews/reviews'

import { importAll } from 'utils/utils'

import surelevation from 'assets/services/surelevation.webp'

export const Surelevation = () => {
    const images = importAll(require.context('assets/realisations/surelevation', false, /\.(png|jpe?g|svg|webp)$/));

    const [loading, setLoading] = useState(Object.keys(images).reduce((acc: any, curr: any) => {
        acc[curr] = true;
        return acc;
    }, {}))

    const handleImageLoad = (key: string) => {
        setLoading((prev: any) => ({ ...prev, [key]: false }))
    }

    return (
        <>
            <div className='surelevation-bg'>
                <div className='bg-black bg-opacity-70 h-full'>
                    <div className='flex flex-col items-center justify-center h-full text-white'>
                        <h1 className='text-6xl lg:text-7xl text-center mt-16 px-4'>SURÉLÉVATION</h1>
                    </div>
                </div>
            </div>

            <div className='bg-[#f7f7f7]'>
                <div className='max-w-7xl mx-auto py-16 px-4'>
                    <div className='flex flex-col md:flex-row gap-8'>
                        <img className='object-cover w-[384px] h-[256px] relative z-10' src={surelevation} alt="surelevation" />
                        <div>
                            <div className='absolute dots w-40 h-40 -translate-x-14 -translate-y-12 z-0' />
                            <div className='relative text-lg'>
                                <h2 className='text-4xl mb-2'>Qu'est-ce que la surélévation ?</h2>
                                <span>La <span className='font-semibold'>surélévation</span> est une technique de construction qui permet d'aménager un espace habitable sous le toit de votre habitation ou de lui ajouter un étage, sans modifier son emprise au sol.</span>
                                <p className='mt-6'>Cette solution d'extension de maison est généralement privilégiée lorsque l'<span className='font-semibold'>aménagement de combles</span> est impossible ou que ce dernier n'offre pas de volume suffisamment confortable. On va alors rehausser la toiture pour obtenir une hauteur sous toit suffisante pour aménager un espace de vie. Ce type de travaux permet également d'améliorer l'<span className='font-semibold'>isolation thermique</span> et <span className='font-semibold'>phonique</span> de la maison.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto px-4 py-16'>
                <div className='absolute dots w-40 h-40 -translate-x-12 -translate-y-6' />
                <div className='relative'>
                    <h3 className='text-4xl'>Nos réalisations de surélévation.</h3>
                    <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center pt-8'>
                        {Object.keys(images).length === 0 && <p>Pas de réalisations.</p>}
                        {Object.keys(images).length > 0 && Object.keys(images).map((key: string, i: number) => (
                            <div key={i}>
                                {loading[key] && (
                                    <Skeleton w={296} h={296} />
                                )}
                                <img className='w-full h-full object-cover aspect-square' src={images[key]} alt={key} loading='lazy' onLoad={() => handleImageLoad(key)} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Contact gray />
            <Reviews reviews={[{ author_name: 'Sergiu Loghin', rating: 5, text: 'Haute qualite!', profile_photo_url: '' }]} />
            <Footer rating={5} user_ratings_total={1} />
        </>
    )
}