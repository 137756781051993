import { AiFillStar } from 'react-icons/ai'
import { FcGoogle } from 'react-icons/fc'

import { ReviewProps } from 'pages/home/home'

export const Reviews = (props: { reviews: ReviewProps[] }) => {
    const { reviews } = props

    return (
        <div className='max-w-7xl py-16 px-4 mx-auto'>
            <div className='absolute dots w-40 h-40 md:-translate-x-20 -translate-y-6' />
            <h3 className='text-4xl text-center md:text-start mb-8 relative'>Les avis de nos clients</h3>
            {reviews.length === 0 && (
                <p className='text-center'>
                    Pas d'avis.
                </p>
            )}
            <div className='flex justify-center flex-wrap gap-8'>
                {reviews.map((review, i) => <Review key={`${i}-r`} {...review} />)}
            </div>
            <div className='flex justify-center mt-8 gap-4 items-center'>
                <p>Voulez-vous laisser un avis ?</p>
                <div style={{ border: '1px solid #2F3543' }} className='px-4 py-2 cursor-pointer border-main-color hover:bg-slate-200' onClick={() => {
                    // window.open('https://g.page/r/CRB-D1XaybCTEBM/review')
                }}>
                    Cliquez ici
                </div>
            </div>
        </div>
    )
}

const Review = (props: ReviewProps) => {
    const { author_name, profile_photo_url, rating, text } = props
    return (
        <div className='max-w-xs w-full bg-[#f7f7f7] relative'>
            <div className='flex p-4 justify-between'>
                {/* <img className='max-w-[48px]' src={profile_photo_url} alt={author_name} /> */}
                <div className=''>
                    <p className='font-bold'>{author_name}</p>
                    <div className='flex'>{Array.from(Array(rating).keys()).map(x => <AiFillStar key={x} color='orange' size={24} />)}</div>
                </div>
                <FcGoogle size={32} />
            </div>
            <div className='w-full h-[1px] bg-white' />
            <p className='p-4'>{text}</p>
        </div>
    )
}