import { useNavigate } from 'react-router-dom';

interface LinkProps {
    label: string;
    url?: string;
    scrolling?: boolean;
    className?: string;
    onClick?: Function;
}

export const Link = (props: LinkProps) => {
    const { label, url, scrolling, className, onClick } = props
    const navigate = useNavigate()

    const handleClick = async () => {
        onClick && onClick();
        if (scrolling && url) {
            await navigate(url)

            let doc = document.getElementById(url)
            doc && window.scrollTo({ top: doc.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })

            return;
        }
    }

    return (
        <li className={`cursor-pointer underline-expand ${className}`} onClick={handleClick}>
            {label}
        </li>
    )
}