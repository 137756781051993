import { useNavigate } from 'react-router-dom';

interface ServiceProps {
    img: string;
    url: string;
    title: string;
}

export const Service = (props: ServiceProps) => {
    const { img, url, title } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(url)
        window.scrollTo({ top: 0 })
    }

    return (
        <div className='relative w-[320px] h-[320px] cursor-pointer hover:shadow-2xl' onClick={handleClick}>
            <img className='w-[320px] h-[320px] object-cover' src={img} alt={title} />
            <div className='bg-black opacity-70 w-full h-full absolute top-0 z-10' />
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-20 text-4xl'>
                {title}
            </div>
        </div>
    )
}