import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { MainLayout } from 'layout/MainLayout'
import { Home } from 'pages/home/home';
import { Couverture } from 'pages/couverture/couverture';
import { Charpente } from 'pages/charpente/charpente';
import { Zinguerie } from 'pages/zinguerie/zinguerie';
import { Surelevation } from 'pages/surelevation/surelevation';
import { Isolation } from 'pages/isolation/isolation';

export const Routing = () => (
    <BrowserRouter>
        <MainLayout>
            <Routes>
                <Route path='*' element={<Home />} />
                <Route path='/couverture' element={<Couverture />} />
                <Route path='/charpente' element={<Charpente />} />
                <Route path='/zinguerie' element={<Zinguerie />} />
                <Route path='/surelevation' element={<Surelevation />} />
                <Route path='/isolation' element={<Isolation />} />
            </Routes>
        </MainLayout>
    </BrowserRouter>
);
